<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Cấu hình OKR</h6>
          <a type="button" href="/#/tools/okr/create" class="btn btn-success float-right"><i class="fa fa-plus"></i> Thêm mới</a>
        </template>
        <div class="filter">
          <b-row>
            <b-col>
              <b-form-group label="Tiêu đề">
                <b-form-input v-model="filter.title"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Trạng thái">
                <b-form-select :options="status" v-model="filter.status"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Thời gian bắt đầu(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_from"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian kết thúc(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_to"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-right">
                <b-button-group>
                  <b-button type="button" variant="primary" @click="search()"><i class="fa fa-search"></i> Tìm kiếm</b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content">
          <b-table :items="items" :fields = "fields" ref="table" striped hover responsive caption-top>
            <template #cell(index)="data">
              {{(currentPage - 1) * 25 + (data.index + 1)}}
            </template>

            <template #cell(title)="data">
              <span :title="data.item.title">{{ subStringContent(data.item.title) }}</span>
            </template>

            <template #cell(caption)="data">
              <span :title="data.item.caption">{{ subStringContent(data.item.caption) }}</span>
            </template>

            <template #cell(target_value)="data">
              {{ numberFormat(parseInt(data.item.target_value)) }}
            </template>
            <template #cell(progress_value)="data">
              {{ numberFormat(parseInt(data.item.progress_value)) }}
            </template>
            <template #cell(created_at)="data">
              {{ formatDate(data.item.created_at) }}
            </template>

            <template #cell(start_date)="data">
              {{ formatDate(data.item.start_date) }}
            </template>

            <template #cell(end_date)="data">
              {{ formatDate(data.item.end_date) }}
            </template>

            <template #cell(execute_date)="data">
              {{ data.item.execute_date ? formatDate(data.item.execute_date) : 'N/A' }}
            </template>

            <template #cell(status)="data">
              {{ transformStatus(data.item.status) }}
            </template>

            <template #cell(detail)="data">
              <a :href="data.item.detail" target="_blank" class="btn btn-primary btn-sm">Cập nhật</a>
            </template>

          </b-table>

          <div class="mt-3">
            <b-pagination-nav
                v-model="currentPage"
                :link-gen="createLinkPage"
                :number-of-pages="paginate.totalPage"
                use-router
                first-number
                last-number
                align="right"
            ></b-pagination-nav>
          </div>
        </div>
      </b-card>
    </b-card-group>

    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "../../../../core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import router from "@/router";

Vue.use(Notifications);

const OkrRepository = RepositoryFactory.get("monitor");
export default {
  components: { AlertDialogue },
  mixins: [Common],
  data() {
    return {
      items: null,
      status: [
        { value: "", text: "Tất cả" },
        { value: "ACTIVATE", text: "Hoạt động" },
        { value: "DEACTIVATE", text: "Không hoạt động" },
      ],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
        status: null,
        title: null
      },
      fields: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "title",
          label: "Tiêu đề",
        },
        {
          key: "caption",
          label: "Mô tả ngắn",
        },
        {
          key: "target_value",
          label: "Mục tiêu",
        },
        {
          key: "progress_value",
          label: "Tiến trình",
        },
        {
          key: "status",
          label: "Trạng thái"
        },
        {
          key: "start_date",
          label: "Ngày bắt đầu"
        },
        {
          key: "end_date",
          label: "Ngày kết thúc"
        },
        {
          key: "created_at",
          label: "Thời gian"
        },
        {
          key: "execute_date",
          label: "Lần cập nhật cuối"
        },
        {
          key: "detail",
          label: "Hành động"
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cấu hình OKR", route: "/tools/okr" },
    ]);
  },
  methods: {
    search() {
      this.filter.page = 1;
      router.push({ path: "/tools/okr", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList();
    },
    async getList()
    {
      this.$bus.$emit("show-loading", true);
      this.items = null;
      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await OkrRepository.listOkr(params);

      this.$bus.$emit("show-loading", false);
      let body = response.data.data;
      if (body.error) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.items = body.data.data;

      if (this.items.length) {
        this.items.forEach((value) => {
          value["detail"] = "#/tools/okr/update?id=" + value.id;
        });
      }


      this.paginate.totalPage = body.data.last_page;
      this.paginate.total = body.data.total;
      this.paginate.page = body.data.current_page;
      this.paginate.limit = body.data.per_page;
    },
    showModal() {
      this.$bvModal.show(this.exportId);
    },
    transformStatus(status) {
      if (status === "ACTIVATE") {
        return "Hoạt động";
      }

      return "Không hoạt động";
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
    subStringContent(string)
    {
      if (!string) {
        return string;
      }

      if (string.length < 30) {
        return string;
      }

      return string.substring(0, 27) + "...";
    }
  },
  created() {
    this.getList();
  },
  watch: {
    currentPage(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getList();
      }
    },
  },
};
</script>
